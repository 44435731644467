header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 50px;
    background: #069FE3 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    @media screen and (max-width: 1500px) {
        padding: 0px 15px;
    }
    .links {
        margin-left: 155px;
        @media screen and (max-width: 1500px) {
            margin-left: 30px;
        }
        a {
            line-height: 25px;
            font-size: 20px;
            color: #fff;
            font-weight: 400;
            text-decoration: none;
            padding: 28px 11px 28px;
            margin: 0 10px;
            display: inline-block;
            border-bottom: 5px solid transparent;
            transition: 0.3s;
            &.active {
                border-color: #fff;
            }
        }
    }

    .search {
        width: 30%;
        max-width: 500px;
        margin-left: auto;
        .input-block {
            margin-bottom: 0;
            .input-icon {
                top: 50%;
            }
            input {
                padding-top: 12px;
                padding-bottom: 12px;
                background: #fff;
                border-color: #fff;
            }
        }
    }
    .user {
        margin-left: 86px;
        @media screen and (max-width: 1500px) {
            margin-left: 30px;
        }
        .user-name {
            margin-right: 20px;
            color: #fff;
            font-size: 18px;
            line-height: 25px;
            text-transform: capitalize;
            font-weight: 400;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .user-avatar {
            width: 56px;
            height: 56px;
            border-radius: 100%;
            svg {
                width: 100%;
                height: 100%;
                path {
                    fill: #f2f2f2;
                }
            }
        }
    }
    .burger {
        display: none;
    }

    .burger div {
        width: 25px;
        height: 3px;
        background-color: white;
        margin: 5px;
        transition: all 0.3s ease;
    }
    @media screen and (max-width: 768px) {
        .burger {
            display: block;
            cursor: pointer;
        }
        .links {
            position: absolute;
            right: 0px;
            height: 0;
            top: 7vh;
            background-color: #069FE3;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            z-index: 5;
            transform: translateX(100%);
            opacity: 0;
            overflow: hidden;
            transition: all 250ms cubic-bezier(0.42, 0, 0.58, 1);
        }

        .nav-active {
            opacity: 1;
            height: 56vh;
            transform: translateX(0);
            overflow: hidden;
            transition: all 250ms cubic-bezier(0.42, 0, 0.58, 1);
        }

        .toggle .line1 {
            transform: rotate(-45deg) translate(-5px, 6px);
        }
        .toggle .line2 {
            opacity: 0;
        }
        .toggle .line3 {
            transform: rotate(45deg) translate(-5px, -6px);
        }
    }
    @media screen and (max-width: 650px) {
        .logo {
            display: none;
        }
    }
}

#simple-menu {
    .MuiPaper-root {
        top: 85px !important;
        transform: translateX(-25%) !important;
    }
}

.MuiPopover-root {
    .MuiPaper-root {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.161);
        border-radius: 0;
        overflow: visible;
        &:before {
            content: "";
            position: absolute;
            top: -10px;
            right: 15px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 5px 10px 5px;
            border-color: transparent transparent #fff transparent;
        }
        ul {
            padding: 0;
            width: 200px;
            li {
                line-height: 39px;
                svg {
                    margin-right: 10px;
                }
                a {
                    color: #000;
                    text-decoration: none;
                }
                &:not(:last-child) {
                    border-bottom: 1px solid #d2d2d2;
                }
            }
        }
    }
}
