@import "variables";

*,
:after,
:before {
  box-sizing: border-box;
  font-family: "Poppins";
}

p {
  color: #000;
  font-size: 18px;
  line-height: 25px;
}
.error-text {
  color: #ff0000;
}

h1 {
  font-size: 47px;
  line-height: 68px;
  font-weight: 700;
}

h2 {
  font-style: 35px;
  line-height: 53px;
  font-weight: 600;
}

h3 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
}

h4 {
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
}
.green {
  color: $mainColor;
}

.red {
  color: $red;
}

.blue {
  color: $secondaryColor;
}

.primary-btn {
  color: #fff;
  font-size: 20px;
  line-height: 27px;
  font-weight: 400;
  padding: 13px 63px 12px 57px;
  background-color: $mainColor;
  border-radius: 28px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.161);
  text-decoration: none;
  transition: 0.3s;
  border: 2px solid $mainColor;
  cursor: pointer;
  &:hover {
    color: $mainColor;
    background-color: #fff;
  }
  &:disabled {
    background-color: #b2b2b2;
    border-color: #b2b2b2;
    &:hover {
      color: #000;
      background-color: #b2b2b2;
      border-color: #b2b2b2;
    }
  }
}

.secondary-btn {
  color: #000;
  font-size: 20px;
  line-height: 27px;
  font-weight: 400;
  padding: 13px 63px 12px 57px;
  background-color: #f2f2f2;
  border-radius: 28px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.161);
  text-decoration: none;
  transition: 0.3s;
  border: 2px solid #f2f2f2;
  cursor: pointer;
  &:hover {
    border-color: transparent;
    background-color: transparent;
  }
}

.text-btn {
  color: $mainColor;
  transition: 0.3s;
  text-decoration: none;
  font-size: 20px;
  line-height: 27px;
  cursor: pointer;
}

button,
input {
  outline: none !important;
}

.container {
  width: 1190px;
  padding: 0 25px;
  margin: 0 auto;
  @media screen and (max-width: 1190px) {
    width: 100%;
  }
}

.surveys-block,
.contacts-block {
  @media screen and (max-width: 700px) {
    width: 1024px;
    border: 1px solid #ededed;
  }
}

.responsive-table {
  @media screen and (max-width: 1024px) {
    overflow-x: auto;
  }
}

.dropdown-menu {
  .MuiPaper-root {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.161);
    border-radius: 0;
    overflow: visible;
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      right: 15px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 10px 5px;
      border-color: transparent transparent #fff transparent;
    }
    ul {
      padding: 0;
      width: 200px;
      li {
        line-height: 39px;
        svg {
          margin-right: 12px;
        }
        a {
          color: #000;
          text-decoration: none;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #d2d2d2;
        }
      }
    }
  }
}

.webLink-wrapper {
  display: flex;
  .input-block {
    flex-grow: 1;
    margin: 0;
  }
  button {
    margin-left: 15px;
    padding-left: 0;
    padding-right: 0;
    width: 150px;
  }
  .input-block.plain input {
    padding-left: 20px;
    border-radius: 5px;
  }
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $mainColor !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.5rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.5rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.ml-5 {
  margin-left: 1.5rem !important;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.d-none {
  display: none;
}

.sub-title-small {
  font-size: 12px;
  color: #7a7a7a;
}

.flex-row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.main-green {
  color: #0fc285;
}
.select-all-container {
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #069FE3;
  }
}

.confirm-modal {
  .confirm-body {
    .confirm-message {
      text-align: center;
      margin-bottom: 50px;
      margin-top: 0;
    }
    .confirm-buttons {
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, auto));
      column-gap: 20px;
      justify-content: center;
      @media screen and (max-width: 700px) {
        grid-template-columns: repeat(2, minmax(100px, 200px));
      }
      button {
        border-radius: 5px;
        @media screen and (max-width: 700px) {
          padding: 13px 5px 12px 5px;
        }
      }
    }
  }
}

.MuiSnackbar-anchorOriginTopRight {
  top: 90px !important;
}

.bold {
  font-weight: 500;
}

.bolder {
  font-weight: 600;
}

.terms-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 5px;
}

#terms-checkbox {
  width: 25px;
  margin-top: 2px;
}

.span-terms {
  color: cornflowerblue;
}

.reports-btns-wrapper {
  display: grid;
  grid-template-columns: minmax(150px, 365px) minmax(150px, 335px) minmax(150px, 380px);
  gap: 30px;
  justify-content: space-around;
  margin: 0px 20px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, minmax(150px, 320px));
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 960px) {
    grid-template-columns: auto;
  }
  button {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 5px;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 960px) and (max-width: 1700px) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  @media (max-width: 568px) {
    button {
      font-size: 16px;
    }
  }
}
