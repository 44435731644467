.MuiFormControl-root {
  width: 100%;
  .MuiInputBase-root {
    color: #7a7a7a !important;
    font-family: "Poppins" !important;
    height: 60px;
    .MuiInputBase-input {
      background: #f2f2f2 0% 0% no-repeat padding-box;
      padding: 19px 70px 18px 24px;
    }
  }
  .MuiInput-underline::after, .MuiInput-underline::before {
    border: none !important;
  }
  .MuiInputAdornment-positionEnd {
    margin-left: 0;
  }
  .MuiButtonBase-root {
    position: absolute;
    right: 20px;
  }
}

.MuiPickersDay-daySelected {
  background-color: #313096 !important;
}

.create-survey-container{
  .additional-fields-container {
    display: flex;

    .additional-fields {
      flex-grow: 1;
      flex-basis: 0;
      display: inline-block;
      margin-top: 10px;
      @media screen and (max-width: 768px){
        label {
          display: flex;
          align-items: flex-start;
          margin-bottom: 10px;
          span {
            padding-top: 0px;
          }
        }
      }
    }
  }
}
