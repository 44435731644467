div.contacts-selector-container, .contact-files-selector-container {
    .custom-select__control:before {
        content: url("../../../shared/assets/contact-icon.svg");
        right: 55px;
        border: none;
        height: 15px;
    }
    .custom-select__option--is-selected {
        background: darken($color: #fff, $amount: 5);
        position: relative;
        &::after {
            content: url("../../../shared/assets/checkedRounded.png");
            position: absolute;
            display: inline-block;
            top: 10px;
            right: 35px;
        }
    }
    .select-all-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    div.input-block {
        margin-top: 10px;
    }
}

.contact-files-selector-container {
    .custom-select__control:before {
        content: url("../../../shared/assets/file-icon.svg");
        right: 55px;
        border: none;
        height: 15px;
    }
}