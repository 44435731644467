@import "../../../../shared/styles/variables";

.add-survey-question-container {
  .content {
    .sub-question-form {
      margin-left: 60px;
      .input-container {
        height: 60px;
        margin-left: 40px;
        &:last-child {
          margin-bottom: 25px;
        }
      }
    }
    .question-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 45px;
      .question-count-container {
        position: relative;
        .question-count {
          font-size: 27px;
          text-align: center;
          margin: 0 auto;
        }
        svg {
          position: absolute;
          top: -20px;
          right: -20px;
          width: 20px;
          cursor: pointer;
        }
      }
      .empty-icon {
        width: 24px;
        height: 24px;
        display: flex;
      }
    }
    .input-container {
      display: grid;
      grid-template-columns: 40px auto 54px;
      margin-bottom: 25px;
      position: relative;
      @media screen and (max-width: 768px) {
        grid-template-columns: 30px auto 54px;
      }
      .input-block {
        width: calc(100% - 40px);
        margin-right: 40px;
        @media screen and (max-width: 768px) {
          width: calc(100% - 20px);
          margin-right: 20px;
        }
      }
    }
    .sub-answer-input-container {
      display: flex;
      justify-content: space-between;
      margin-left: 40px;
      position: relative;
      .input-block {
        width: 80%;
        margin-right: 40px;
        @media screen and (max-width: 768px) {
          width: 95%;
          margin-right: 20px;
        }
      }
    }
    .input-container,
    .sub-answer-input-container {
      .primary-btn {
        position: absolute;
        height: 55px;
        border-radius: 5px;
        background-color: $mainColor;
        color: white;
        border: none;

        &:disabled {
          background-color: #bebebe;
          cursor: not-allowed;
        }
        @media screen and (max-width: 768px) {
          padding: 10px;
        }
      }
      > svg {
        margin-top: 17px;
      }
      .answers-btns {
        margin-top: 17px;
        display: flex;

        svg {
          cursor: pointer;
          &:first-child {
            margin-right: 5px;
          }
        }
      }
      .sub-question-btn-wrapper {
        height: 55px;
        grid-column-start: 2;
      }
    }
    .buttons-wrapper {
      margin-top: 39px;
      display: flex;
      justify-content: space-between;
      button {
        text-transform: uppercase;
        width: 190px;
        padding: 0px;
        height: 60px;
        font-size: 18px;
        &.save {
          color: white;
          background: #7a7a7a 0% 0% no-repeat padding-box;
          border-color: #7a7a7a;
          svg {
            vertical-align: text-bottom;
          }
          &:hover {
            color: #7a7a7a;
            background: #fff;
          }
        }
      }
    }
    .questions-buttons-wrapper {
      margin-top: 39px;
      display: flex;
      justify-content: space-around;
      button {
        text-transform: uppercase;
        width: 190px;
        padding: 0px;
        height: 60px;
        font-size: 18px;
        &.save {
          color: white;
          background: #7a7a7a 0% 0% no-repeat padding-box;
          border-color: #7a7a7a;
          svg {
            vertical-align: text-bottom;
          }
          &:hover {
            color: #7a7a7a;
            background: #fff;
          }
        }
      }
      @media screen and (max-width: 768px) {
        display: grid;
        grid-template-columns: repeat(3, minmax(80px, 200px));
        column-gap: 20px;
        button {
          white-space: no-wrap;
          width: 100%;
        }
      }
      @media screen and (max-width: 550px) {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 20px;
        button {
          white-space: no-wrap;
          width: 100%;
        }
      }
    }
  }

  .sub-question-representative {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 80px;
    border-radius: 4px;
    padding: 17px 19px;
    background-color: #f2f2f2;
    border: 2px solid #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sub-question-representative-btns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
    }
  }
}
.modal-container .modal-component .modal-body {
  max-height: 80vh;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }
}

.option-item {
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
}
