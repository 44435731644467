@import "../../../shared/styles/variables";

.create-survey-modal {
  input {
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 70px;
  }
  .format-types {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    .format-type {
      width: calc(50% - 10px);
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.161);
      border: 1px solid #707070;
      border-radius: 5px;
      padding: 30px 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 700px) {
        flex-direction: column;
      }
      .format-icon {
        margin-right: 20px;
        svg path {
          fill: $mainColor;
        }
      }
      .format-description {
        h4 {
          margin-top: 0;
          margin-bottom: 10px;
        }
        p {
          color: #7a7a7a;
          font-size: 16px;
          line-height: 21px;
        }
      }
      &.active {
        background: $mainColor;
        h4,
        p {
          color: #fff;
        }
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  .buttons-wrapper {
    text-align: center;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    button {
      border-radius: 5px;
    }
  }
}

.add-survey-logo-container {
  h3 {
    font-size: 27px;
    text-align: center;
    margin-bottom: 0;
  }
  .buttons-wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    .secondary-btn {
      width: 260px;
    }

    .primary-btn {
      border-radius: 5px;
      width: 260px;
    }
  }

  .fileContainer {
    box-shadow: none;
    display: flex;
    flex-direction: column-reverse;
    .deleteImage {
      width: 24px;
      height: 24px;
      background: #000;
      font-size: 10px;
      line-height: 24px;
    }
  }
  .chooseFileButton.primary-btn {
    background-color: $mainColor;
    border-radius: 5px;
    color: black;
    font-size: 14px;
    line-height: 18px;
    line-height: 34px;
    width: 170px;
    margin-top: 0;
    border: 2px solid $mainColor;
    &:hover {
      color: $mainColor;
      background-color: #ffffff;
    }
  }
  .default-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 240px;
    background: #f2f2f2 0% 0% no-repeat padding-box;
    border-radius: 5px;
    margin: 30px auto 0;
  }
  .error-text {
    margin-bottom: 0;
    text-align: center;
  }
}
