input {
  width: 100%;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 38px;
  font-size: 18px;
  line-height: 23px;
  color: #7a7a7a;
  border: 2px solid #f2f2f2;
  padding: 17px 19px 16px 70px;
  &[type="password"] {
    padding-right: 80px;
  }
  @media screen and (max-width: 1050px) {
    padding-left: 17px;
  }
}

.input-block {
  position: relative;
  margin-bottom: 26px;
  .input-icon,
  .input-password-icon {
    position: absolute;
    top: 30px;
    transform: translateY(-50%);
    left: 30px;
    display: flex;
    svg path {
      fill: #7a7a7a;
    }
  }
  .input-password-icon {
    left: auto;
    right: 50px;
    cursor: pointer;
    &.shown {
      svg path {
        fill: #000;
      }
    }
  }
  .input-icon {
    @media screen and (max-width: 1050px) {
      svg {
        visibility: hidden;
      }
    }
  }
  &.input-error {
    margin-bottom: 10px;
    input {
      border-color: #ff0000;
      color: #ff0000;
      &::-webkit-input-placeholder {
        color: #ff0000;
      }
      &:-ms-input-placeholder {
        color: #ff0000;
      }
      &::-moz-placeholder {
        color: #ff0000;
      }
      &:-moz-placeholder {
        color: #ff0000;
      }
    }
    .input-icon,
    .input-password-icon {
      svg path {
        fill: #ff0000;
      }
    }
    .input-icon + input + .error-text,
    .input-password-icon + input + .error-text {
      margin-left: 70px;
    }
    .input-icon + input + .error-text-no-icon,
    .input-password-icon + input + .error-text-no-icon {
      margin-left: 20px;
    }

    .custom-select {
      .custom-select__control {
        border-color: #ff0000;
      }
    }
  }
  .error-text {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  &.plain {
    input {
      padding-right: 20px;
    }
  }
  .color-red {
    color: #ff0000;
  }
}
.otp-input-block {
  .otp-wrapper {
    display: flex;
    align-items: center;
    @media screen and (max-width: 620px) {
      display: block;
    }
    &-fields {
      display: flex;
    }
  }
  input {
    border-radius: 5px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    display: inline-block;
    &:not(:first-child) {
      margin-left: 20px;
    }
    @media screen and (min-width: 621px) {
      width: 52px;
    }
  }
  .resend {
    font-size: 16px;
    line-height: 25px;
    padding: 8px 25px;
    margin-left: 20px;
    @media screen and (max-width: 620px) {
      margin-left: 0px;
      margin-top: 20px;
    }
  }
  .error-text {
    margin-left: 0;
  }
}

.custom-select {
  .custom-select__control {
    background-color: #f2f2f2;
    color: #7a7a7a;
    cursor: pointer;
    display: flex;
    min-height: 60px;
    padding-left: 12px;
    position: relative;
    border-width: 2px;
    border-color: transparent;
    &:hover {
      border-color: transparent;
    }
    &:before {
      content: "";
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: #7a7a7a transparent transparent transparent;
    }
    .custom-select__indicator-separator,
    .custom-select__indicator {
      display: none;
    }
    .custom-select__value-container {
      padding-right: 55px;
    }
  }
  .custom-select__menu {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.161);
    border-radius: 0;
    margin-top: 0;
    .custom-select__menu-list {
      .custom-select__option {
        color: #7a7a7a;
        font-size: 18px;
        line-height: 25px;
        padding: 10px 40px 10px 30px;
        cursor: pointer;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        &.custom-select__option--is-focused {
          // color: #fff;
          // background: #069FE3;
          background: darken($color: #fff, $amount: 1);
        }
        &.custom-select__option--is-selected {
          // color: #fff;
          background: darken($color: #fff, $amount: 5);
          position: relative;
          &::after {
            content: url("../../assets/checkedRounded.png");
            position: absolute;
            display: inline-block;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
          }
        }
        .option-item {
          display: block;
          align-items: center;
          overflow: hidden;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .custom-select__multi-value {
    background: #fff;
    border-radius: 5px;
    .custom-select__multi-value__label {
      font-size: 18px;
      padding: 9px 10px;
    }
    .custom-select__multi-value__remove {
      width: 14px;
      height: 14px;
      background: #000000;
      padding: 0;
      border-radius: 100%;
      margin-top: 11px;
      margin-right: 10px;
      path {
        fill: #fff;
      }
    }
  }

  .custom-select__single-value {
    max-width: calc(100% - 65px);
  }
}

.search-input--wrapper,
.search-input--header-wrapper {
  display: flex;
  align-items: center;
  border-radius: 38px;
}

.search-input--wrapper {
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #7a7a7a;
}

.search-input--header-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  border: none;
  background-color: #f2f2f2;
  align-self: center;
  margin-left: 5px;
  margin-right: 5px;
}

.search-input__search-btn {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0px;
  padding-left: 30px;
  padding-right: 15px;
  cursor: pointer;
}

.search-input__cancel-btn,
.search-input__cancel-btn--visible {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0px 0px 0px 2px;
  border-radius: 100%;
  margin-right: 5px;
  visibility: hidden;
}

.search-input__cancel-btn--visible {
  visibility: visible;
  cursor: pointer;
}

.search-input__input {
  padding: 0px !important;
  border: none !important;
  border-radius: 0 !important;
  font-size: 16px;
}
