@import "../../../styles/variables";

.route-spinner {
    color: #474747;
    padding: 50px;
    text-align: center;
    width: 100%;
}
.MuiCircularProgress-circle {
    color: $mainColor;
}
