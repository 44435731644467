@import "../../../../shared/styles/variables";

.add-survey-collector-container {
    .collector-types {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        @media screen and (max-width: 700px) {
            flex-direction: column;
        }
        .collector-type {
            width: calc(50% - 15px);
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.161);
            border: 1px solid #707070;
            border-radius: 5px;
            padding: 30px 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            @media screen and (max-width: 700px) {
                width: unset;
                &:first-child {
                    margin-bottom: 30px;
                }
            }
            svg path {
                fill: $mainColor;
            }
            .collector-icon {
                margin-right: 20px;
                font-size: 46px;
            }
            .collector-description {
                h4 {
                    margin-top: 0;
                    margin-bottom: 10px;
                }
                p {
                    color: #7a7a7a;
                    font-size: 16px;
                    line-height: 21px;
                }
            }
            &.active {
                background: $mainColor;
                h4,
                p {
                    color: #fff;
                }
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }
    .input-block {
        margin-top: 26px;
        .error-text {
            text-align: left;
        }
    }
    .add-contacts-container {
        .contact-switcher {
           .left-part {
               margin-right: 35px;
           }
        }
        .contacts-selector-container, .contact-files-selector-container {
            position: relative;
            margin-top: 10px;
            .select-all-container {
                position: absolute;
                top: -60px;
                right: 0;
                @media screen and (max-width: 700px) {
                    position: unset;
                }
            }
        }
        .input-block {
            margin-bottom: 0px !important;
        }
    }
    .add-collector-buttons-wrapper {
        display: grid;
        grid-template-columns: repeat(2, minmax(100px, auto));
        column-gap: 20px;
        justify-content: center;
        @media screen and (max-width: 700px) {
            grid-template-columns: repeat(2, minmax(100px, 200px));
        }
        button {
            border-radius: 5px;
            @media screen and (max-width: 700px) {
                padding: 13px 5px 12px 5px;
            }
        }
    }
    .mt-26px {
        margin-top: 26px;
    }
    .error-text {
        text-align: center;
        margin-bottom: 0;
    }
}
