.modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 30px;
    .modal-component {
        background-color: #ffffff;
        position: relative;
        border-radius: 7px;
        align-self: center;
        margin: 0 auto;
        width: 700px;
        outline: none;
        @media screen and (max-width: 768px) {
            width: 500px;
        }
        @media screen and (max-width: 576px) {
            width: 95%;
        }
        .modal-header {
            padding: 0 40px;
            background: #dfffef 0% 0% no-repeat padding-box;
            position: relative;
            padding: 16px 30px;
            h2 {
                margin: 0;
                font-weight: 500;
                font-size: 28px;
                color: #069FE3;
                text-align: center;
                max-width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .close-icon {
                cursor: pointer;
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);
                svg {
                    width: 24px;
                }
            }
        }
        .modal-body {
            padding: 50px 40px;
            > .error-text {
                text-align: center;
                margin-bottom: -10px;
            }
        }
    }
}
