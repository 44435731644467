@import "../../../../shared/styles/variables";

.sub-question-form {
  input {
    border-radius: 4px;
    padding: 17px 19px;
  }
  .input-container {
    margin-left: 40px;
    &:last-child {
      margin-bottom: 25px;
    }
  }
  .primary-btn {
    height: 55px;
    border-radius: 5px;
    background-color: $mainColor;
    color: white;
    border: none;
    margin-bottom: 26px;
    @media screen and (max-width: 768px) {
      padding: 10px;
    }

    &:hover {
      background-color: $mainColor;
      color: white;
    }

    &:disabled {
      background-color: #bebebe;
      cursor: not-allowed;
    }
  }

  .input-container {
    margin-top: 26px;
    display: grid;
    grid-template-columns: 40px auto 54px;
    margin-bottom: 25px;
    position: relative;
    @media screen and (max-width: 768px) {
      grid-template-columns: 30px auto 54px;
    }
    .input-block {
      width: calc(100% - 40px);
      margin-right: 40px;
      @media screen and (max-width: 768px) {
        width: calc(100% - 20px);
        margin-right: 20px;
      }
    }
    .primary-btn {
      position: absolute;
      height: 55px;
      border-radius: 5px;
      background-color: $mainColor;
      color: white;
      border: none;
      @media screen and (max-width: 768px) {
        padding: 10px;
      }


    &:disabled {
      background-color: #bebebe;
      cursor: not-allowed;
    }
    }
    > svg {
      margin-top: 17px;
    }
    .answers-btns {
      margin-top: 17px;
      display: flex;

      svg {
        cursor: pointer;
        &:first-child {
          margin-right: 5px;
        }
      }
    }
    .sub-question-btn-wrapper {
      height: 55px;
      grid-column-start: 2;
    }
  }

  .sub-question-btn-wrapper {
    height: 55px;
    grid-column-start: 2;
  }

  .questions-buttons-wrapper {
    margin-top: 39px;
    display: flex;
    justify-content: space-between;
    button {
      border-radius: 28px;
      text-transform: uppercase;
      width: 190px;
      padding: 0px;
      height: 60px;
      font-size: 18px;
      &.save {
        color: white;
        background: $mainColor 0% 0% no-repeat padding-box;
        border-color: $mainColor;
        svg {
          vertical-align: text-bottom;
        }
        &:hover {
          color: white;
          background: $mainColor;
        }
      }
    }
  }

  .sub-question-representative {
    margin-top: 16px;
    margin-left: 80px;
    border-radius: 4px;
    padding: 17px 19px;
    background-color: #f2f2f2;
    border: 2px solid #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sub-question-representative-btns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
    }
  }
}
