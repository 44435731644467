@import "../../../shared/styles/variables";

.question-container {
  margin-top: 30px;
  margin-bottom: 50px;
  width: calc(100vw - 30px);
  .imgLogo {
    text-align: center;
    img {
      width: 200px;
    }
  }
  .question-item {
    margin-top: 50px;

    .buttons-wrapper {
      margin-top: 0;
      margin-bottom: 15px;
      button {
        box-shadow: none;
        border-radius: 3px;
        padding: 5px 10px;
        font-size: 16px;
        &:not(:first-child) {
          margin-left: 5px;
        }
        &.active {
          border: 1px solid $mainColor;
        }
      }
    }
    .move-controls {
      margin-top: 10px;
      .MuiFormControl-root {
        min-width: 100px;
        &:not(:first-child) {
          margin-left: 15px;
        }
      }
      .primary-btn {
        vertical-align: bottom;
        margin-left: 15px !important;
      }
    }
  }
  .buttons-wrapper {
    margin-top: 50px;
  }
  .scroll-btn {
    padding: 3px 25px;
    font-size: 18px;
    margin-top: 15px;
  }
  .questions-counter-container {
    position: fixed;
    bottom: 0px;
    background-color: #fff;
    left: 0px;
    width: 100%;
    z-index: 10;
    .questions-counter {
      background-color: #069FE3;
      z-index: 20;
      padding: 12px 30px 12px 15px;
      text-align: center;
      border-top-right-radius: 50px;
      width: 160px;
      height: 70px;
      display: grid;
      align-items: center;

      span {
        font-size: 24px;
        margin-top: 10px;
      }
    }
  }
}

.pb-70px {
  padding-bottom: 70px;
}

.analyse-container {
  margin-top: 50px;
  .question-chart {
    margin-bottom: 50px;
  }
  table {
    width: 100%;
    margin-top: 50px;
    border-spacing: 0;
    tr {
      th {
        text-align: left;
        &:not(:first-child) {
          padding-left: 15px;
        }
      }
      td,
      th {
        padding: 5px 15px;
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
        &:last-child {
          border-right: 1px solid #ccc;
        }
        span {
          float: right;
        }
      }
    }
    tfoot {
      th {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }
    }
  }
}

.collector-container {
  h3 {
    text-align: center;
  }
  .surveys-controls {
    justify-content: flex-end;
  }
}

.action-menu {
  .MuiPaper-root {
    transform: translate(-97px, 50px) !important;
    ul {
      width: 150px;
    }
  }
}
.buttons-wrapper {
  text-align: center;
  margin-top: 50px;
}

.summary-container {
  h3 {
    margin-top: 50px;
    text-align: center;
  }
  .page-title {
    position: relative;
    button {
      position: absolute;
      top: -20px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 20px;
      color: #7a7a7a;
      path {
        fill: #7a7a7a;
      }
    }
  }
  .summary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 45px;
    h3 {
      margin: 0;
      font-weight: 500;
    }
    .primary-btn {
      font-size: 16px;
      line-height: 25px;
      padding: 8px 38px;
      border-radius: 5px;
    }
  }
  .summary-wrapper {
    margin: 40px -20px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      align-items: center;
    }
    .summary-block {
      margin-left: 20px;
      margin-right: 20px;
      width: calc(25% - 45px);
      @media screen and (max-width: 700px) {
        width: 80%;
        padding-bottom: 20px;
      }
      &.big {
        width: calc(50% - 30px);
        @media screen and (max-width: 700px) {
          width: 80%;
        }
      }
      &:not(.big) {
        .summary-body {
          height: 131px;
          display: flex;
          align-items: center;
          justify-content: center;
          b {
            font-size: 50px;
            line-height: 76px;
          }
          span {
            font-size: 20px;
            line-height: 3;
            color: #e39b30;
          }
          .open {
            color: #098800;
          }
          .closed {
            color: #000000;
          }
          .draft {
            color: #616362;
          }
          .pending {
            color: #e49a2f;
          }
          .rejected {
            color: #e04d13;
          }
        }
      }
      .summary-header {
        background: $mainColor;
        color: #fff;
        font-size: 16px;
        line-height: 40px;
        text-align: center;
        height: 40px;
      }
      .summary-body {
        box-shadow: 0px 0px 6px #00000029;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 35px;
        padding-top: 25px;
        h3 {
          margin-top: 0px;
          margin-bottom: 20px;
          text-align: left;
        }
        p {
          margin-bottom: 20px;
          font-size: 16px;
          line-height: 25px;
          color: #7a7a7a;
        }
        .count-wrapper {
          display: flex;
          justify-content: space-around;
          margin-top: 35px;
          .count-block {
            box-shadow: 0px 0px 6px #00000029;
            width: 166px;
            height: 121px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            b {
              font-size: 34px;
              line-height: 51px;
            }
            span {
              font-size: 16px;
              line-height: 25px;
              color: #7a7a7a;
            }
          }
        }
      }
    }
  }
}
